import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '主页',
    component: HomeView
  },
  {
    path: '/tools',
    name: '工具',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ToolsView.vue')
  },
  {
    path: '/file',
    name: '留言',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LeaveMessage/LeaveMessage.vue')
  },

  {
    path: '/GuidCreater',
    name: 'Guid生成器',
    meta: { icon: "guide-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/GuidCreater.vue')
  },
  {
    path: '/Base64Converter',
    name: 'Base64转换器',
    meta: { icon: "exchange", parent: "tools" },
    component: () => import('../views/ToolsSubViews/Base64Converter.vue')
  },
  {
    path: '/WhatIsLunch',
    name: '中午吃啥?',
    meta: { icon: "shop-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/WhatIsLunch.vue')
  },
  {
    path: '/INeedPullShit',
    name: '我要拉屎!',
    meta: { icon: "fire-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/INeedPullShit.vue')
  },
  {
    path: '/RealSalary',
    name: '真实工资计算器',
    meta: { icon: "balance-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/RealSalary.vue')
  },
  {
    path: '/QRCode',
    name: '二维码生成器',
    meta: { icon: "qr", parent: "tools" },
    component: () => import('../views/ToolsSubViews/QRCode.vue')
  },
  {
    path: '/PlayFCGame',
    name: 'FC',
    meta: { icon: "smile-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/PlayFCGame.vue')
  },
  {
    path: '/HitMouse',
    name: '打地鼠',
    meta: { icon: "good-job-o", parent: "tools" },
    component: () => import('../views/ToolsSubViews/HitMouse.vue')
  },
  // {
  //   path: '/KFC50',
  //   name: '肯德基点餐',
  //   meta: { icon: "good-job-o", parent: "tools" },
  //   component: () => import('../views/ToolsSubViews/KFC50.vue')
  // },

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
