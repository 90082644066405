
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const that = reactive({
      title: computed(() => {
        return route.name;
      }),

      history: computed(() => {
        return route.meta.parent && route.meta.parent == "tools";
      }),

      onClickLeft: function () {
        router.back();
      },
    });

    return {
      ...toRefs(that),
    };
  },
});
